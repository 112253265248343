import React, { useEffect } from "react";
import styles from "./ActiveCampaignFormNewsletterOnly.module.scss";

const ActiveCampaignFormNewsletterOnly = () => {
	useEffect(() => {
		const script = document.createElement("script");

		script.type = "text/javascript";
		script.src = "/activecampaign_script.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<>
			<form
				method="POST"
				action="https://pipedout.activehosted.com/proc.php"
				id="_form_5_"
				className={`_form _form_5 _inline-form  _dark activeCampaignInline`}
				noValidate
				data-styles-version="5"
				data-target="hub"
			>
				<input type="hidden" name="u" value="5" />
				<input type="hidden" name="f" value="5" />
				<input type="hidden" name="s" />
				<input type="hidden" name="c" value="0" />
				<input type="hidden" name="m" value="0" />
				<input type="hidden" name="act" value="sub" />
				<input type="hidden" name="v" value="2" />
				<input
					type="hidden"
					name="or"
					value="d948dc9bfd6ea7c4cddbd8b496caafbf"
				/>
				<div className="_form-content">
					<div className="_form_element _x22521592 _full_width _clear">
						<div className="_html-code">
							<h3>
								Get better with data. SEO & good old fashioned
								analytics.
							</h3>
							<p>
								Get the best of SEO and analytics in your inbox.
							</p>
							<p>
								Not often (one every 1-2 months), but that just
								means it&apos;s even better when it comes.
							</p>
						</div>
					</div>
					<div className="_form_element _x99890639 _full_width ">
						<div className="_field-wrapper">
							<input
								type="text"
								id="email"
								name="email"
								placeholder="Email Address"
								required
							/>
						</div>
					</div>
					<div className="_button-wrapper _full_width">
						<button
							id="_form_5_submit"
							className="_submit button buttonSecondary m-t-md"
							type="submit"
						>
							Submit
						</button>
					</div>
					<div className="_clear-element"></div>
				</div>
				<div
					className="_form-thank-you"
					style={{ display: null }}
				></div>
			</form>
		</>
	);
};

export default ActiveCampaignFormNewsletterOnly;
