import React from "react";
import Helmet from "react-helmet";
import config from "data/SiteConfig";

// Layout needs columns
import "normalize.css";
import "src/styles/main.scss";

const LayoutLandingPage = ({ children }) => (
	<React.Fragment>
		<Helmet>
			<meta name="description" content={config.siteDescription} />
		</Helmet>
		{children}
	</React.Fragment>
);

export default LayoutLandingPage;
