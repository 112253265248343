import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import logoImg from "static/images/logos/logo_colour.png";
import socialImage from "static/images/social_sharing/newsletter.png";

// Local imports
import Layout from "src/layouts/LayoutLandingPage";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import ActiveCampaignFormNewsletterOnly from "src/components/generic/ActiveCampaignFormNewsletterOnly/ActiveCampaignFormNewsletterOnly";
import styles from "./newsletter.module.scss";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Sign up for our newsletter",
	subTitle: null
};

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	const title = "Newsletter Signup";
	const description =
		"Once a month newletter that will make you twice as good with your marketing data.";
	// const socialImage = data.socialImage.childImageSharp.fluid;

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO
				pageTitle={title}
				pageDescription={description}
				pageImage={"/images/social_sharing/newsletter.png"}
			/>
			<div
				className={`container maxContainer outerContainer ${styles.fullHeightSection}`}
			>
				<div className="columns is-vcentered is-centered">
					<div
						className={`column is-full-mobile ${styles.formContainer}`}
					>
						<Link to="/" className="m-r-lg m-t-xs">
							<div>
								<img
									className={`${styles.smallLogo}`}
									src={logoImg}
									alt="Piped Out Logo"
								></img>
							</div>
						</Link>
						<ActiveCampaignFormNewsletterOnly />
					</div>
				</div>
			</div>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
// export const pageQuery = graphql`
// 	query Newletter {
// 		# This gets the main image
// 		socialImage: file(
// 			relativePath: { eq: "images/social_sharing/newsletter.png" }
// 		) {
// 			childImageSharp {
// 				fluid(maxWidth: 1200, maxHeight: 675) {
// 					...GatsbyImageSharpFluid
// 				}
// 			}
// 		}
// 	}
// `;
